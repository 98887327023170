import React from "react";
import { Container, Col, Row, Card } from "react-bootstrap";

import styles from "./BoardScreen.module.css";
import FadeInAnimation from "../../components/animations/FadeInAnimation";

const BoardScreen = () => {
  const bio = [
    {
      id: 1,
      imageUrl: "/img/committees/ed_hardy.jpeg",
      name: "Ed Hardy",
    },
    {
      id: 2,
      imageUrl: "/img/committees/mary_cockerham.jpeg",
      name: "Mary Cockerham",
    },
    {
      id: 3,
      imageUrl: "/img/committees/audrey_jones.jpeg",
      name: "Audrey Jones",
    },
    {
      id: 4,
      imageUrl: "/img/committees/arvis_singleton.jpeg",
      name: "Arvis Singleton",
    },
    {
      id: 5,
      imageUrl: "/img/committees/shelena_szrom.jpeg",
      name: "Shelena Szrom",
    },
    {
      id: 6,
      imageUrl: "/img/committees/allen_gill.jpeg",
      name: "Allen Gill",
    },

    // Add more events as needed
  ];
  return (
    <Container>
      <h1 className="title">Our Board</h1>
      <br />
      <Row className="justify-content-md-center">
        {bio.map((bio) => (
          <Col key={bio.id} md={6} sm={12}>
            <FadeInAnimation>
              <Card className={styles.cardStyles}>
                <Card.Img
                  variant="top"
                  src={bio.imageUrl}
                  className={styles.cardImg}
                />
                <Card.Body>
                  <Card.Title className={styles.cardTitle}>
                    {bio.name}
                  </Card.Title>
                  <Card.Text className={styles.cardText}>
                    {bio.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </FadeInAnimation>
          </Col>
        ))}
      </Row>
      <hr />
      <h1 className="title">Our Committees</h1>

      <img src="/img/committees/committeepic1.jpg" width="100%" />

      <hr />
      <img src="/img/committees/committeepic2.jpg" width="100%" />
    </Container>
  );
};

export default BoardScreen;
