import React from "react";
import { Container, Col, Row, Carousel } from "react-bootstrap";
import TestimonialCarousel from "../../components/page-components/TestimonialCarousel";

import styles from "./CommunityScreen.module.css";
import FadeInAnimation from "../../components/animations/FadeInAnimation";
import RisingInAnimation from "../../components/animations/RisingInAnimation";
const CommunityScreen = () => {
  const imageUrls = [
    "/img/4clubsphotos/1.jpg",
    "/img/4clubsphotos/2.jpg",
    "/img/4clubsphotos/3.jpg",
    "/img/4clubsphotos/4.jpg",
    "/img/4clubsphotos/5.jpg",
    "/img/4clubsphotos/6.jpg",
    "/img/4clubsphotos/7.jpg",
    "/img/4clubsphotos/8.jpg",
    "/img/4clubsphotos/9.jpg",
    "/img/4clubsphotos/10.jpg",
    "/img/4clubsphotos/11.jpg",
    "/img/4clubsphotos/12.jpg",
  ];

  return (
    <Container>
      <h1 className="title">Community Outreach</h1>
      <Row className="justify-content-md-center">
        <Col sm={12}>
          <FadeInAnimation>
            <RisingInAnimation>
              <div className="text-box">
                <p>
                  LOYM's mission is greater than making a difference in the
                  lives of the Moms we have the privilege to serve, but our
                  mission is to also make a difference in our communities
                  especially those that value our most prized possession--our
                  children.
                  <br />
                  <br />
                  This is why LOYM chose to partner with Lil 4s Kids Club a and
                  our teachers as one of our few community outreach initiatives.
                  We not only partnered with them, but supported these amazing
                  individuals and organizations who work tirelessly to serve our
                  children, who are in essence our future.
                  <br />
                  <br />
                  In 2020, LOYM extended its Community Outreach Initiatives to
                  local teachers of the Keller and Fort Worth area by blessing
                  them with gift cards to their preferred retailer, where they
                  could purchase necessary classroom supplies needed for the
                  school year or they provided teachers with LOYM Blessing Boxes
                  composed of their top 10 needed classroom supplies.
                  <br />
                  <br />
                  LOYM is grateful to have so many of our amazing LOYM Moms
                  answer the call each year to show our love and appreciation to
                  Viola's House, Pregnancy Help For You, and our local teachers
                  as we know without their generous support none of it would be
                  possible.
                  <br />
                  <br />
                  We also provide donation items such as baby and hygienic
                  products to{" "}
                  <a
                    href="https://violashouse.org/about-violas-house/"
                    target="blank"
                  >
                    Viola's House
                  </a>{" "}
                  a nonprofit that provides housing for pregnant homeless teens.
                </p>
              </div>
            </RisingInAnimation>
          </FadeInAnimation>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col sm={12}>
          <h1 className="header">
            Teacher Blessing/Viola's House/Pregnancy Help For You
          </h1>
          <Carousel className={styles.carousel}>
            {imageUrls.map((imageUrl, index) => (
              <Carousel.Item key={index}>
                <img src={imageUrl} className={styles.img} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
      <br />
      <br />
      <TestimonialCarousel />
      <br />
      <br />
    </Container>
  );
};

export default CommunityScreen;
